import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
Amplify.configure(aws_exports);

async function handleAuthStateChange (nextAuthState, data) {
  // const [cookies, setCookie] = useCookies(['name']);
  console.log('Handle auth change', nextAuthState, data);
  if (nextAuthState === 'signedin') {
    console.log('Writing auth cookie');
    document.cookie = `session-id=1234; Secure`;
    document.cookie = `accessToken=${data.signInUserSession.accessToken.jwtToken}; Secure`;
    document.cookie = `idToken=${data.signInUserSession.idToken.jwtToken}; Secure`;
    document.cookie = `refreshToken=${data.signInUserSession.refreshToken.token}; Secure`;
    window.location.href = '/';
  } else {
    document.cookie = 'session-id= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
  }
}

class App extends Component {
  render() {
    return (
      <div className="App">
        <h1>Auth application</h1>
        <AmplifySignOut />
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    );
  }
}

export default withAuthenticator(App, { handleAuthStateChange });
