/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:37a1fceb-a0a5-4c95-a1a5-79e6e0aee65e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_FMHx1cgtw",
    "aws_user_pools_web_client_id": "2dd952sl0ib51bl7krv1rlc0vq",
    "oauth": {},
    "aws_content_delivery_bucket": "upwork-madison-auth-20210804100737-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d311x0tzbcg2dj.cloudfront.net"
};


export default awsmobile;
